/*!
 * @author Dennis Morhardt <info@dennismorhardt.de>
 */
@charset 'UTF-8';

// Load fonts
@import 'base/fonts';

// Reset body
html,
body {
  background-color: #fff;
  margin: 0;
}

/* stylelint-disable selector-class-pattern */
.dpa_app {
  font-family: 'TheSans', sans-serif;
}

.dpa_app .dpa_widget-title .dpa_pageTitle1 {
  text-transform: none !important;
}

.dpa_bar-par_de_1 {
  order: -1;
}

.bg_par_de_1 {
  background-color: #e3000f !important;
}

.bg_par_de_6 {
  background-color: #8b4513 !important;
}
